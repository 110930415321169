<app-app-layout>
  <div class="container">
    <div class="row">
      <div class="col-lg-12 col-sm-12 col-md-12" style="margin-bottom: 4%">
        <mat-accordion>
          <mat-expansion-panel>
            <mat-expansion-panel-header>
              <mat-panel-title>
                Email: {{ subscriberDetail.email }}
              </mat-panel-title>
            </mat-expansion-panel-header>
            <div class="d-flex">Gym Name: {{ subscriberDetail.subName }}</div>
            <div class="d-flex">Name: {{ subscriberDetail.name }}</div>
          </mat-expansion-panel>
        </mat-accordion>
      </div>
      <div
        class="col-lg-12 col-sm-12 col-md-12"
        style="margin-bottom: 4%"
        *ngIf="isUnknownUser()"
      >
        <mat-card style="padding: 2% 0">
          Choose country
          <br />
          <mat-radio-group
            [(ngModel)]="isInternational"
            (change)="changeCountryEvent()"
          >
            <mat-radio-button color="primary" value="india">
              India
            </mat-radio-button>
            &nbsp;&nbsp;
            <mat-radio-button
              color="primary"
              value="international"
              class="plan-padding-5"
            >
              International(Outside India)
            </mat-radio-button>
          </mat-radio-group></mat-card
        >
      </div>
    </div>
    <div class="row">
      <mat-tab-group
        (selectedTabChange)="onTabChangeEvent($event)"
        class="col-lg-12 col-sm-12 col-md-12"
      >
        <mat-tab
          [label]="plan.name"
          *ngFor="let plan of plans; let last = last"
        >
          <mat-card style="margin-bottom: 3%">
            <mat-card-content>
              <div class="text-align-center">
                <!-- <div class="mat-h1 d-flex justify-content-between">
                <mat-checkbox
                  (change)="checkboxChangeEvent(plan)"
                  color="primary"
                  [(ngModel)]="plan.selectedPlan"
                >
                  {{ plan.name }}</mat-checkbox
                >
              </div> -->
                <div class="mat-h2">
                  <span *ngIf="isInternational === 'india'">₹</span>
                  <span *ngIf="isInternational !== 'india'">$</span>
                  {{ localizedCurrency(plan.amount) }}
                </div>
              </div>
              <div *ngFor="let detail of plan.details">
                <div class="d-flex align-items-center pt-1">
                  <mat-icon matListIcon style="margin-right: 1.2%"
                    >check_circle_outline</mat-icon
                  >
                  {{ detail }}
                </div>
              </div>
            </mat-card-content>
          </mat-card>
        </mat-tab>
      </mat-tab-group>
    </div>

    <button
      mat-raised-button
      class="mat-display-2 w-100"
      style="height: 7%; font-size: 24px"
      *ngIf="selectedPlan.amount"
      (click)="payNow()"
      color="primary"
    >
      <div style="display: flex">
        Pay &#160;
        <span *ngIf="isInternational === 'india'">₹</span>
        <span *ngIf="isInternational !== 'india'">$</span>
        {{ localizedCurrency(selectedPlan.amount) }}
      </div>
    </button>
  </div>
</app-app-layout>
