export default class Payment {
  amount: string
  currency: string
  name: string = 'Gymbook'
  description: 'Manage your gym anytime any where.'
  image?: string
  prefill?: PaymentContact
  notes: noets
}

export interface PaymentContact {
  name: string
  email: string
  contact: string
}

export interface noets {
  paymentId: string
}
