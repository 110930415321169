<app-app-layout>
  <mat-card class="w-100 h-100" style=" padding:4%">
    <mat-card-header>
      <h5 class="width-full">
        Sing in to your account
      </h5>
    </mat-card-header>
    <mat-card-content>
      <form [formGroup]="userForm">
        <div class="col-12">
          <mat-form-field class="w-100">
            <input
              matInput
              placeholder="Email"
              name="email"
              formControlName="email"
            />
          </mat-form-field>
        </div>

        <div class="col-12">
          <button
            mat-raised-button
            class="primary"
            [disabled]="!userForm.valid"
            (click)="btnLogin()"
          >
            Submit
          </button>

          <div class="col-12">
            <div style="margin-top: 15%; font-size: 16px;">
              <div>
                Have any question?
              </div>
              <div class="flex-center">
                <div class="icon">
                  <mat-icon
                    aria-hidden="false"
                    [inline]="true"
                    style="font-size: 16px;"
                    >alternate_email</mat-icon
                  >
                </div>

                &nbsp;&nbsp;
                <a href="mailto:help@gymbook.in">help@gymbook.in</a>
              </div>
            </div>
          </div>
        </div>
      </form>
    </mat-card-content>
  </mat-card>
</app-app-layout>
