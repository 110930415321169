import Payment from 'src/app/pojos/payment'
import { ActivatedRoute } from '@angular/router'
import { Component, OnInit } from '@angular/core'
import { NgxSpinnerService } from 'ngx-spinner'
import { RzpPaymentService } from 'src/app/services/rzp-payment.service'
import { TosterService } from 'src/app/services/toster.service'
import { UtilService } from 'src/app/services/util.service'

@Component({
  selector: 'app-sms-buy',
  templateUrl: './sms-buy.component.html',
  styleUrls: ['./sms-buy.component.scss']
})
export class SmsBuyComponent implements OnInit {
  perSms: number = 0.24
  numberOfSms: number = 1000
  email: string = null
  redirectUrl: string = null
  constructor(
    private spinner: NgxSpinnerService,
    private rzpService: RzpPaymentService,
    private toast: TosterService,
    private util: UtilService,
    private route: ActivatedRoute
  ) { }

  ngOnInit() {
    this.redirectUrl = this.route.snapshot.queryParamMap.get('redirectUrl')

  }
  totalAmountToPay() {
    return Math.ceil(this.numberOfSms * this.perSms)
  }

  async payNow() {
    let that = this
    try {
      let payment: Payment = await this.rzpService.createPaymentRequestForSmsPlan(
        this.perSms,
        this.numberOfSms
      )

      let orderId = await this.rzpService.createRzpOrder(payment.amount, payment.notes.paymentId, payment.currency)
      await this.rzpService.acceptPaymentFromRzp(payment, orderId)
      if (this.util.isNotNull(this.redirectUrl)) {
        window.open(this.redirectUrl, '_self')
      } else {
        window.open(location.origin + '/payment?s=s', '_self')
      }
    } catch (error) {
      that.spinner.hide()
      if (
        this.util.isNotNull(error) &&
        this.util.isNotNull(error.error) &&
        this.util.isNotNull(error.error.message)
      ) {
        this.toast.showErrorToast(error.error.message)
      } else {
        window.open(location.origin + '/payment?s=f', '_self')
      }
    }
  }
}
