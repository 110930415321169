import { ActivatedRoute, Router } from "@angular/router";
import { Component, OnInit } from "@angular/core";
import { CookiesStorageService } from "src/app/services/cookies-storage.service";
import {
  UntypedFormControl,
  UntypedFormGroup,
  Validators,
} from "@angular/forms";
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentApiService } from "src/app/api/payment-api.service";
import { TosterService } from "src/app/services/toster.service";
import { User } from "src/app/pojos/user";
import { UtilService } from "src/app/services/util.service";

@Component({
  selector: "app-log-in",
  templateUrl: "./log-in.component.html",
  styleUrls: ["./log-in.component.scss"],
})
export class LogInComponent implements OnInit {
  user: User = new User();
  cookieValue;
  userForm = new UntypedFormGroup({
    email: new UntypedFormControl(this.user.email, [Validators.required]),
  });
  constructor(
    private spinner: NgxSpinnerService,
    private cookieStorage: CookiesStorageService,
    private toast: TosterService,
    private paymentService: PaymentApiService,
    private router: Router,
    private route: ActivatedRoute,
    private util: UtilService
  ) {}

  async ngOnInit() {
    // Login with user email
    this.loginUserWithEmail();
  }

  /**
   * Login user with email
   */
  async loginUserWithEmail() {
    this.user.email = this.route.snapshot.queryParamMap.get("email");
    let url = this.route.snapshot.queryParamMap.get("url");
    const token = this.route.snapshot.queryParamMap.get("token");

    this.userForm.patchValue({
      email: this.user.email,
    });

    try {
      if (this.util.isNotNull(this.user.email)) {
        this.spinner.show();
        let response: any = await this.paymentService.loginUserTemp(this.user);
        this.cookieStorage.set(
          CookiesStorageService.USER_TOKEN,
          response.data.authToken
        );
        this.spinner.hide();
        if (url && url.includes("sms-buy")) {
          this.router.navigate(["/sms-buy"]);
        } else {
          this.router.navigate(["/plan"]);
        }
      } else if (token) {
        this.spinner.show();
        let response: any = await this.paymentService.generateTempAuthToken(
          token
        );
        this.cookieStorage.set(
          CookiesStorageService.USER_TOKEN,
          response.data.authToken
        );
        this.spinner.hide();
        if (url && url.includes("sms-buy")) {
          this.router.navigate(["/sms-buy"]);
        } else {
          this.router.navigate(["/plan"]);
        }
      }
    } catch (error) {
      this.toast.showErrorToast(error.error.message);
      this.spinner.hide();
    }
  }

  // Navigate to auth app
  btnLogin() {
    this.user.email = this.userForm.get("email").value;
    this.util.redirectUrlToAuth("/plan", "email=" + this.user.email);
  }
}
