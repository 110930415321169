import { Component, OnInit } from '@angular/core';

@Component({
  selector: 'app-for-zero-four',
  templateUrl: './for-zero-four.component.html',
  styleUrls: ['./for-zero-four.component.scss']
})
export class ForZeroFourComponent implements OnInit {

  constructor() { }

  ngOnInit() {
  }

}
