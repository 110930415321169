import { DOCUMENT } from "@angular/common";
import { environment } from "src/environments/environment";
import { Inject, Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class UtilService {
  constructor(@Inject(DOCUMENT) private document: Document) {}

  isNull(val: any): boolean {
    return val === null || val === undefined;
  }

  isNotNull(val: any): boolean {
    return !this.isNull(val);
  }

  localizedCurrency(num: number) {
    return Number(num.toFixed(1)).toLocaleString();
  }

  isEmptyStr(str: any) {
    return this.isNull(str) || (typeof str === "string" && str.length === 0);
  }

  /**
   * Redirect to auth page
   * @param url
   */
  redirectUrlToAuth(url: string, queryParam?: string) {
    let finalUrl = environment.APP_BASE_URL + url;
    let redirectUrl =
      environment.AUTH_APP_BASE_URL +
      "?redirectUrl=" +
      finalUrl +
      "&" +
      queryParam;
    this.document.location.href = redirectUrl;
  }
}
