<app-app-layout>
  <div
    class="container"
    style="height: 30vh;display: flex;justify-content: center;align-items: center;"
  >
    <div class="row">
      <mat-card>
        <div>
          <div class="font-weight-bold">
            Buy SMS
          </div>
          <div style="color: black">* Per sms {{perSms}} Paisa</div>
          <br />
        </div>
        <mat-card-content>
          <mat-form-field style="width: 100%">
            <input
              matInput
              placeholder="Number of sms you want eg. 2000"
              [(ngModel)]="numberOfSms"
            />
          </mat-form-field>

          <button
            mat-raised-button
            class="mat-display-2 w-100"
            style="font-size: 15px;background-color: #fb641b; color: #fff;"
            (click)="payNow()"
          >
            PAY NOW ({{totalAmountToPay()}})
          </button>
        </mat-card-content>
      </mat-card>
    </div>
  </div>
</app-app-layout>
