<div
  class="container d-flex justify-content-center align-items-start mt-5"
  style="height: 100vh"
>
  <div
    class="row d-flex justify-content-center"
    style="text-align: center; min-width: 50%; "
  >
    <h3 style="margin-bottom: 5%">GymBook</h3>

    <ng-content></ng-content>
  </div>
</div>
