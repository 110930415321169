import { AngularMaterialModule } from "./angular-material.module";
import { AppComponent } from "./app.component";
import { AppRoutingModule } from "./app-routing.module";
import { BrowserAnimationsModule } from "@angular/platform-browser/animations";
import { BrowserModule } from "@angular/platform-browser";
import { CookieService } from "ngx-cookie-service";
import { FormsModule, ReactiveFormsModule } from "@angular/forms";
import { ForZeroFourComponent } from "./components/for-zero-four/for-zero-four.component";
import { HTTP_INTERCEPTORS, HttpClientModule } from "@angular/common/http";
import { HttpInterceptorService } from "./api/http-interceptor.service";
import { LogInComponent } from "./components/log-in/log-in.component";
import { NgModule } from "@angular/core";
import { NgxSpinnerModule } from "ngx-spinner";
import { PaymentComponent } from "./components/payment/payment.component";
import { PlanComponent } from "./components/plan/plan.component";
import { SmsBuyComponent } from "./components/sms-buy/sms-buy.component";
import { ToastrModule } from "ngx-toastr";
import { AppLayoutComponent } from "./components/app-layout/app-layout.component";

@NgModule({
  declarations: [
    AppComponent,
    LogInComponent,
    PlanComponent,
    ForZeroFourComponent,
    PaymentComponent,
    SmsBuyComponent,
    AppLayoutComponent,
  ],
  imports: [
    BrowserModule,
    AppRoutingModule,
    BrowserAnimationsModule,
    AngularMaterialModule,
    FormsModule,
    HttpClientModule,
    ReactiveFormsModule,
    ToastrModule.forRoot(), // ToastrModule added,
    NgxSpinnerModule,
  ],
  providers: [
    CookieService,
    {
      provide: HTTP_INTERCEPTORS,
      useClass: HttpInterceptorService,
      multi: true,
    },
  ],
  bootstrap: [AppComponent],
})
export class AppModule {}
