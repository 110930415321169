<div
  class="container d-flex justify-content-center align-items-center"
  style="height: 100vh;"
>
  <div class="row">
    <mat-card *ngIf="paymentStatus === true">
      <div style="font-size: 2rem">
        Your payment process successfully.<br />
        Check your mail for invoice.<br />
        For any support related query<br />
        mailus:
        <a href="mailto:help@gymbook.in" target="_top">help@gymbook.in</a>
      </div>
    </mat-card>
    <mat-card *ngIf="paymentStatus === false">
      <div style="font-size: 2rem">
        Your payment fail.<br />
        If amount deducted from your bank please<br />
        mailus:
        <a href="mailto:help@gymbook.in" target="_top">help@gymbook.in</a>
      </div>
    </mat-card>
  </div>
</div>
