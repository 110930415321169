import { Component, OnInit } from "@angular/core";
import { ActivatedRoute } from "@angular/router";

@Component({
  selector: "app-payment",
  templateUrl: "./payment.component.html",
  styleUrls: ["./payment.component.scss"]
})
export class PaymentComponent implements OnInit {
  paymentStatus: boolean;
  constructor(private route: ActivatedRoute) {}

  ngOnInit() {
    let status = this.route.snapshot.queryParamMap.get("s");
    if (status === "s") {
      this.paymentStatus = true;
    } else {
      this.paymentStatus = false;
    }
  }
}
