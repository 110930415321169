import { catchError } from "rxjs/operators";
import { CookiesStorageService } from "../services/cookies-storage.service";
import { environment } from "../../environments/environment";
import { HttpClient, HttpHeaders } from "@angular/common/http";
import { Injectable } from "@angular/core";
import { throwError } from "rxjs";
import { User } from "../pojos/user";

@Injectable({
  providedIn: "root",
})
export class PaymentApiService {
  baseUrl: string = environment.baseUrl;
  constructor(
    private http: HttpClient,
    private cookieStorage: CookiesStorageService
  ) {}

  loginUserTemp(user: User) {
    return this.http
      .post(this.baseUrl + "/subscriber/login-temp", {
        ...user,
        type: "payment",
      })
      .toPromise();
  }

  getPaymentPlan(country: string) {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });

    return this.http
      .get(`${this.baseUrl}/payment/plan?country=${country}`, { headers })
      .pipe(
        catchError((err) => {
          return throwError(err);
        })
      );
  }

  createPaymentRequest(planId: string) {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });
    return this.http
      .post(this.baseUrl + "/payment/request", { planId }, { headers })
      .toPromise();
  }

  createPaymentRequestForSMS(persms: number, purchaseSms: number) {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });
    return this.http
      .post(
        this.baseUrl + "/payment/request",
        { persms, purchaseSms, type: "SMS_PAYMENT" },
        { headers }
      )
      .toPromise();
  }

  getPaymentInformation(paymentId: string) {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });
    return this.http
      .get(this.baseUrl + "/payment/info?paymentId=" + paymentId, { headers })
      .toPromise();
  }

  getSubscriberDetail(): Promise<any> {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });
    return this.http.get(this.baseUrl + "/subscriber", { headers }).toPromise();
  }

  generateTempAuthToken(token: string): Promise<any> {
    return this.http
      .get(this.baseUrl + "/subscriber/generate-session-token?key=" + token)
      .toPromise();
  }

  createRzpOrder(order: any): Promise<any> {
    let headers = new HttpHeaders({
      authToken: this.cookieStorage.getAuthToken(),
    });
    return this.http
      .post(this.baseUrl + "/payment/rzp-order", { ...order }, { headers })
      .toPromise();
  }
}
