import Payment from '../pojos/payment'
import { environment } from 'src/environments/environment'
import { Injectable } from '@angular/core'
import { PaymentApiService } from '../api/payment-api.service'

@Injectable({
  providedIn: 'root'
})
export class RzpPaymentService {
  constructor(private paymentApi: PaymentApiService) { }

  async createPaymentRequestForGymManagementAtRzp(
    planId: string
  ): Promise<Payment> {
    let createPaymentRequest: any = await this.paymentApi.createPaymentRequest(
      planId
    )
    let paymentInformation: any = await this.paymentApi.getPaymentInformation(
      createPaymentRequest.data.paymentId
    )
    let paymentInfo: any = paymentInformation.data.paymentInfo
    let userInfo: any = paymentInformation.data.userInfo
    let payment = new Payment()
    payment.amount = paymentInfo.amount
    payment.prefill = {
      name: userInfo.name,
      contact: userInfo.mobile,
      email: userInfo.email
    }
    payment.notes = {
      paymentId: paymentInfo.paymentId
    }
    payment.currency = paymentInfo.currency
    return payment
  }

  async createPaymentRequestForSmsPlan(perSms: number, purchaseSms: number) {
    let createPaymentRequest: any = await this.paymentApi.createPaymentRequestForSMS(
      perSms,
      purchaseSms
    )
    let paymentInformation: any = await this.paymentApi.getPaymentInformation(
      createPaymentRequest.data.paymentId
    )
    let paymentInfo: any = paymentInformation.data.paymentInfo
    let userInfo: any = paymentInformation.data.userInfo
    let payment = new Payment()
    payment.amount = paymentInfo.amount
    payment.prefill = {
      name: userInfo.name,
      contact: userInfo.mobile,
      email: userInfo.email
    }
    payment.notes = {
      paymentId: paymentInfo.paymentId
    }
    payment.currency = paymentInfo.currency
    return payment
  }

  acceptPaymentFromRzp(payment: Payment, orderId: string) {
    return new Promise((resolve, reject) => {
      var options = {
        key: environment.rzpKey,
        amount: payment.amount,
        currency: payment.currency,
        name: payment.name,
        description: payment.description,
        image: 'https://payments.gymbook.in/favicon.ico',
        handler: function (response) {
          resolve(response)
        },
        prefill: payment.prefill,
        notes: payment.notes,
        theme: {
          color: '#009688'
        },
        order_id: orderId
      }
      // return the global native browser window object
      let _window: any = window
      let rzp = new _window.Razorpay(options)
      rzp.open()
    })
  }

  async createRzpOrder(amount: number | string, paymentId: string, currency: string) {
    let body = {
      amount,
      receipt: paymentId,
      currency: currency || 'INR'
    }

    let paymentInformation: any = await this.paymentApi.createRzpOrder(body)
    let data
    if (paymentInformation) {
      data = paymentInformation.data.id
    }
    return data
  }
}
