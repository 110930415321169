import { Injectable } from "@angular/core";
import { ToastrService } from "ngx-toastr";

@Injectable({
  providedIn: "root"
})
export class TosterService {
  constructor(private toastr: ToastrService) {}

  showErrorToast(message) {
    this.toastr.error(message, "Error");
  }
}
