import { CookieService } from "ngx-cookie-service";
import { EncryptService } from "./encrypt.service";
import { environment } from "src/environments/environment";
import { Injectable } from "@angular/core";
@Injectable({
  providedIn: "root",
})
export class CookiesStorageService {
  static USER_TOKEN = "USER";
  constructor(
    private cookieService: CookieService,
    private encrypt: EncryptService
  ) {}

  set(key: string, value: any) {
    this.cookieService.set(key, this.encrypt.encryptData(value), {
      domain: environment.COOKIES_BASE_URL,
      path: "/",
      secure: false,
      sameSite: "Lax",
    });
  }
  get(key: string) {
    return this.encrypt.decryptData(this.cookieService.get(key));
  }
  delete(key: string) {
    this.cookieService.delete(key);
  }

  deleteAll() {
    this.cookieService.deleteAll();
  }

  isExist(key: string) {
    return this.cookieService.check(key);
  }

  getAuthToken() {
    return this.get(CookiesStorageService.USER_TOKEN);
  }
}
