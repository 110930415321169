import { ActivatedRouteSnapshot, Router, RouterStateSnapshot, UrlTree } from '@angular/router'
import { CookiesStorageService } from '../services/cookies-storage.service'
import { Injectable } from '@angular/core'
import { UtilService } from '../services/util.service'

@Injectable({
  providedIn: 'root'
})
export class AuthGuardGuard  {
  constructor(
    private cookieService: CookiesStorageService,
    private router: Router,
    private util: UtilService
  ) { }

  canActivate(
    route: ActivatedRouteSnapshot,
    state: RouterStateSnapshot
  ): boolean {
    if (this.util.isEmptyStr(this.cookieService.getAuthToken())) {
      this.router.navigate(['/login'], {
        queryParams: { ...route.queryParams, url: state.url }
      })
      return false
    }
    return true
  }
}
