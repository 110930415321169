import { NgModule } from '@angular/core'
import { Routes, RouterModule } from '@angular/router'

import { LogInComponent } from './components/log-in/log-in.component'
import { PlanComponent } from './components/plan/plan.component'
import { AuthGuardGuard } from './auth/auth-guard.guard'
import { ForZeroFourComponent } from './components/for-zero-four/for-zero-four.component'
import { PaymentComponent } from './components/payment/payment.component'
import { SmsBuyComponent } from './components/sms-buy/sms-buy.component'

const routes: Routes = [
  {
    path: '',
    pathMatch: 'full',
    redirectTo: 'plan'
  },
  { path: 'login', component: LogInComponent },
  { path: 'plan', component: PlanComponent, canActivate: [AuthGuardGuard] },
  {
    path: 'payment',
    component: PaymentComponent,
    canActivate: [AuthGuardGuard]
  },
  {
    path: 'sms-buy',
    component: SmsBuyComponent,
    canActivate: [AuthGuardGuard]
  },
  { path: '**', component: ForZeroFourComponent }
]

@NgModule({
  imports: [RouterModule.forRoot(routes, {})],
  exports: [RouterModule]
})
export class AppRoutingModule {}
