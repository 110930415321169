import Payment from "src/app/pojos/payment";
import { ActivatedRoute } from "@angular/router";
import { Component, EventEmitter, OnInit, Output } from "@angular/core";
import { MatSnackBar } from "@angular/material/snack-bar";
import { NgxSpinnerService } from "ngx-spinner";
import { PaymentApiService } from "src/app/api/payment-api.service";
import { RzpPaymentService } from "src/app/services/rzp-payment.service";
import { UtilService } from "src/app/services/util.service";
import { MatTabChangeEvent } from "@angular/material/tabs";

declare var window: any;
@Component({
  selector: "app-plan",
  templateUrl: "./plan.component.html",
  styleUrls: ["./plan.component.scss"],
})
export class PlanComponent implements OnInit {
  constructor(
    private snakbar: MatSnackBar,
    private paymentApi: PaymentApiService,
    private spinner: NgxSpinnerService,
    private rzpService: RzpPaymentService,
    private util: UtilService,
    private route: ActivatedRoute
  ) {}
  plans = [];
  selectedPlan: any = {};
  isInternational: string = "india";
  redirectUrl: string = null;
  subscriberDetail: any = {};

  userType: UserType = UserType.UN_KNOWN;

  ngOnInit() {
    this.redirectUrl = this.route.snapshot.queryParamMap.get("redirectUrl");
  }

  ngAfterContentInit(): void {
    this.getSubscriberDetails();
  }

  async getSubscriberDetails() {
    try {
      let subscriber = await this.paymentApi.getSubscriberDetail();
      this.subscriberDetail = {
        subName: subscriber.data.subName,
        email: subscriber.data.email,
        name: subscriber.data.name,
      };

      if (subscriber?.data?.countryCode) {
        if (subscriber?.data?.countryCode.toLowerCase() === "in") {
          this.userType = UserType.INDIAN;
          this.isInternational = "india";
        } else {
          this.userType = UserType.INTERNATIONAL;
          this.isInternational = "international";
        }
      }

      this.getPlan();
    } catch (error) {}
  }

  onTabChangeEvent(changeEvent: MatTabChangeEvent) {
    this.selectedPlan = this.plans[changeEvent.index];
  }

  getPlan() {
    this.spinner.show();
    this.paymentApi.getPaymentPlan(this.isInternational).subscribe(
      (response: any) => {
        this.spinner.hide();
        this.plans = response.data;
        this.selectedPlan = this.plans[0];
      },
      (error) => {
        this.spinner.hide();
      }
    );
  }

  localizedCurrency(num) {
    if (this.util.isNull(num)) {
      return;
    }
    return this.util.localizedCurrency(num);
  }

  async payNow() {
    let that = this;
    try {
      let payment: Payment =
        await this.rzpService.createPaymentRequestForGymManagementAtRzp(
          this.selectedPlan.planId
        );
      let orderId = await this.rzpService.createRzpOrder(
        payment.amount,
        payment.notes.paymentId,
        payment.currency
      );
      await this.rzpService.acceptPaymentFromRzp(payment, orderId);
      if (this.util.isNotNull(this.redirectUrl)) {
        window.open(this.redirectUrl, "_self");
      } else {
        window.open(location.origin + "/payment?s=s", "_self");
      }
    } catch (error) {
      that.spinner.hide();
      window.open(location.origin + "/payment?s=f", "_self");
    }
  }

  changeCountryEvent() {
    this.getPlan();
  }

  isUnknownUser() {
    return this.userType === UserType.UN_KNOWN;
  }
}

enum UserType {
  UN_KNOWN,
  INDIAN,
  INTERNATIONAL,
}
